import { bem } from '@lib/bem';
import clsx from 'clsx';
import { forwardRef } from 'react';
import './ProgressLine.scss';

const {
  block,
  element,
} = bem('progress-line-container');

export const displayProgress = (lineElement, progress) => {
  if (lineElement) {
    const valueInPercents = progress * 100;
    lineElement.style.setProperty('--progress-line-value', `${valueInPercents >= 100 ? 100 : valueInPercents}%`);
  }
};

const ProgressLine = forwardRef(({
  className,
  initialProgress = 0,
  direction = 'horizontal',
  progressIndicatorClassName,
}, ref) => {
  const isHorizontal = direction === 'horizontal';

  return (
    <div
      ref={ref}
      {...block({}, clsx(
        className,
        'relative rounded-[8px] bg-blue-4',
        isHorizontal ? 'h-[2px] w-full' : 'w-[2px]',
      ))}
    >
      <div className={clsx(
        element('line').className,
        'rounded-[8px] bg-blue-2',
        isHorizontal ? 'h-[2px] w-[var(--progress-line-value)]' : 'h-[var(--progress-line-value)] w-[2px]',
      )}
      />
    </div>
  );
});

export default ProgressLine;
