import { RequestFeedback } from '@features/request-feedback';
import { bem } from '@lib/bem';
import StaticIcon from '@ui/StaticIcon';
import Image from '@ui/Image';

import Typography from '@ui/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import Typist from 'react-text-typist';
import { BlurredQR } from '../BlurredQR';
import CheckText from '../CheckText';

import './HeroSection.scss';
import { CircleBlur } from '../CircleBlur';

const {
  block,
  element,
} = bem('hero-section');

const Benefits = ({
  benefits,
  t,
}) => {
  return (
    <div className={clsx(
      'lg:grid-cols sm:space-y-6 sm:py-7 md:flex-col md:space-y-7 md:py-8 lg:flex lg:py-9',
    )}
    >
      {
        benefits.map((benefit, i) => (
          <div key={i} className="lg:col-4 sm:flex sm:flex-col sm:items-center md:text-left">
            <StaticIcon folder="main-page" name={benefit.icon} />
            <Typography variant="header5" className="mt-3 lg-only:h-[60px]">
              {benefit.title}
            </Typography>
            <Typography color="deepBlue3" className="mt-2">
              {benefit.body}
            </Typography>
          </div>
        ))
      }
    </div>
  );
};

const WavesDivider = () => {
  return (
    <div {...element('waves-divider')} />
  );
};

const HeroSection = ({
  className,
  t,
  containerClass,
}) => {
  const subjects = t('hero_section.subjects');
  const checkTexts = t('hero_section.check_texts');

  const imageClass = '!absolute top-0';

  return (
    <div data-anchor="" {...block({}, className)}>
      <div className={clsx('grid-layout sm:mt-7 md:mt-12', containerClass)}>
        <div className="lg:flex">
          <div {...element('left', {}, 'flex flex-col lg:col-7')}>
            <Typography variant="header1" className="font-bold">
              {t('hero_section.header')}
            </Typography>
            <Typography variant="header1" className="font-bold">
              <Typist
                sentences={subjects}
                loop
                cursorClassName={element('typing-cursor').className}
                typingSpeed={100}
              />
            </Typography>
            <Typography
              className="mt-4-md mt-3-xs-only"
              variant="subhead1"
              color="deepBlue3"
            >
              {t('hero_section.subheader')}
            </Typography>
            <div {...element('buttons-group')}>
              <RequestFeedback.Demo.Button>
                {t('request_demo')}
              </RequestFeedback.Demo.Button>
              <RequestFeedback.Call.Button className="ml-2-md mt-2-xs-only" outline>
                {t('request_call')}
              </RequestFeedback.Call.Button>
            </div>
            <div {...element('check-texts', {}, 'lg:space-x-2 md:space-y-2 sm:space-y-[10px]')}>
              {
                React.Children.toArray(checkTexts.map((text) => (
                  <CheckText text={text} />
                )))
              }
            </div>
          </div>
          <div {...element('hero-image')}>
            <CircleBlur className={clsx(
              'absolute top-[332px] right-[119px]',
              'md:top-[399px] md:right-[223px]',
              'sm:top-[290px] sm:right-[111px]',
            )}
            />
            <StaticIcon
              name="mockup"
              className={clsx(
                imageClass,
                'z-10',
                'left-[98px] top-3',
                'sm:left-[60px] sm:h-[340px] sm:w-[166px]',
              )}
            />
            <StaticIcon
              name="hero-hand"
              className={clsx(
                imageClass,
                'z-20',
                'left-4 top-[142px] h-[528px] w-[449px]',
                'sm:h-[314px] sm:w-[268px]',
                'sm:top-[96px] sm:left-2.5',
              )}
            />
            {/* <BlurredQR */}
            {/*   className={clsx( */}
            {/*     imageClass, */}
            {/*     'z-30', */}
            {/*     'top-[501px]', */}
            {/*     'sm:h-10 sm:w-10', */}
            {/*     'sm:top-[328px] sm:left-[15px]', */}
            {/*   )} */}
            {/* /> */}
            <video
              autoPlay
              loop
              muted
              playsInline
              className={clsx(
                imageClass,
                'top-5 left-[113px]',
                'h-[535px] w-[249px]',
                'sm:left-[68px] sm:top-[33px] sm:h-[322px] sm:w-[149px]',
              )}
            >
              <source src="/tagpoint-home-video.mp4" type="video/mp4" />
              Your browser does not support HTML5 video.
            </video>
          </div>
        </div>
        <WavesDivider />
        <Benefits benefits={t('benefits')} t={t} />
      </div>
    </div>
  );
};

HeroSection.defaultProps = {
  className: '',
};

HeroSection.propTypes = {
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
  containerClass: PropTypes.string,
};

export default HeroSection;
