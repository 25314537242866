import { bem } from '@lib/bem';
import ProgressLine from '@ui/ProgressLine';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import ReactSlick from 'react-slick';
import StaticIcon from '@ui/StaticIcon';
import './style.scss';

const { block } = bem('custom-slider');

const sliderSettings = {
  dots: true,
  arrows: false,
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Slider = ({
  className,
  children,
  settings,
  variableWidth,
  gutter,
  showProgress,
  progressBarClassName,
}) => {
  const sliderRef = useRef();
  const progressLineRef = useRef();

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const mergedSettings = { ...sliderSettings, ...settings };

  const calculateProgressLineWidth = useCallback((next) => {
    const slidesCount = sliderRef.current.props.children.length;
    let progress = ((next + mergedSettings.slidesToShow) / slidesCount) * 100;
    if (next + mergedSettings.slidesToShow === slidesCount) {
      progress = 100;
    }
    return progress;
  }, [mergedSettings.slidesToShow]);

  const [initialProgress, setInitialProgress] = useState();
  useEffect(() => {
    if (sliderRef.current) {
      const width = calculateProgressLineWidth(0);
      setInitialProgress(width);
    }
  }, [calculateProgressLineWidth, mergedSettings.slidesToShow]);

  const handleBeforeChange = (prev, next) => {
    if (showProgress) {
      if (next === 0) {
        progressLineRef.current.style.setProperty('--progress-line-value', `${initialProgress}%`);
      } else {
        progressLineRef.current.style.setProperty('--progress-line-value', `${calculateProgressLineWidth(next)}%`);
      }
    }
    setCurrentSlideIndex(next);
  };

  const renderCustomPaging = useCallback((i) => {
    if (i === Math.ceil(currentSlideIndex / mergedSettings.slidesToScroll)) {
      return (
        <div className="relative">
          <StaticIcon name="slider-dot-circle" className="!border-0" />
          <StaticIcon name="slider-dot-semi-circle" className="absolute top-0.5 left-[-3px] !border-0" />
        </div>
      );
    }
    return (
      <StaticIcon name="inactive-slider-dot" className="!border-0" />
    );
  }, [currentSlideIndex, mergedSettings.slidesToScroll]);

  const appendDots = useCallback((dots) => {
    return (
      <ul className="slick-dots">
        {dots}
      </ul>
    );
  }, []);

  const finalConfig = {
    beforeChange: handleBeforeChange,
    customPaging: renderCustomPaging,
    appendDots,
    ...mergedSettings,
    dots: showProgress ? false : mergedSettings.dots,
  };

  return (
    <div {...block({
      variableWidth,
      gutter,
    }, className)}
    >
      <ReactSlick
        ref={sliderRef}
        {...finalConfig}
      >
        {children}
      </ReactSlick>
      {
        (showProgress && initialProgress) && (
          <ProgressLine
            className={clsx(
              'mb-4',
              progressBarClassName,
            )}
            ref={progressLineRef}
            initialProgress={initialProgress}
          />
        )
      }
    </div>
  );
};

Slider.defaultProps = {
  className: '',
};

Slider.propTypes = {
  className: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default Slider;
