import { MAIN_PAGE } from '@assets/content/data';
import { RequestFeedback } from '@features/request-feedback';
import { bem } from '@lib/bem';
import { t as translate } from '@lib/i18n';
import MediaQuery from '@ui/MediaQuery';
import Typography from '@ui/Typography';
import WavesDivider from '@ui/WavesDivider';
import { Layout } from '@widgets/layout';
import clsx from 'clsx';
import BWTicker from 'routes/main-page/ui/BWTicker';
import CTA from 'routes/main-page/ui/CTA';
import DiscussProblemBlock from 'routes/main-page/ui/DiscussProblemBlock';
import ExtraFeatures from 'routes/main-page/ui/ExtraFeatures';
import Functional from 'routes/main-page/ui/Functional';

import HeroSection from 'routes/main-page/ui/HeroSection';
import PilotProject from 'routes/main-page/ui/PilotProject';
import RoleBenefits from 'routes/main-page/ui/RoleBenefits';
import StatTicker from 'routes/main-page/ui/StatTicker';
import WorkWithIssues from 'routes/main-page/ui/WorkWithIssues';

import './style.scss';
import { Industries } from '../ui/Industries';
import { Feedback } from '../ui/Feedback';

const {
  block,
  element,
} = bem('main-page');

const t = (key, blockName = '') => translate(`main_page.${blockName ? `${blockName}.` : ''}${key}`);

function BlockCaption({
  children,
  bgcolor,
  color,
  className,
  center = true,
  textClassName,
}) {
  return (
    <div className={clsx('row', className, center && 'center')}>
      <Typography
        {...element('caption', { bgcolor }, textClassName)}
        variant="caption2"
        color={color}
        uppercase
      >
        {children}
      </Typography>
    </div>
  );
}

function Home() {
  const statTickerItems = t('stat_ticker.items');
  const pilotDays = t('pilot_project.days');

  return (
    <Layout
      seo={MAIN_PAGE.en.seo}
    >
      <div {...block()}>
        <RequestFeedback.Call.Modal />
        <RequestFeedback.Consultation.Modal />
        <RequestFeedback.Problem.Modal />
        <HeroSection t={t} />
        <Functional t={t} BlockCaption={BlockCaption} />
        <DiscussProblemBlock t={t} />
        <Industries t={t} BlockCaption={BlockCaption} />
        <WorkWithIssues t={t} BlockCaption={BlockCaption} />
        <MediaQuery greaterThanOrEqual="md">
          <div>
            <BWTicker tickerItems={t('benefits_ticker.items')} />
          </div>
        </MediaQuery>
        <div className={clsx(
          'lg-down:bg-[url(../../../static/images/main-page/ticker-bg.png)]',
          'md:[background-position:left_calc(50%_+_80px)_top_322px]',
          'sm:[background-position:left_0_top_230px]',
          'bg-no-repeat',
        )}
        >
          <RoleBenefits t={t} />
          <StatTicker tickerItems={statTickerItems} />
          <PilotProject t={t} days={pilotDays} />
        </div>
        <div className="w-full bg-white">
          <WavesDivider />
        </div>
        <ExtraFeatures t={t} items={t('extra_features.items')} BlockCaption={BlockCaption} />
        <CTA items={t('cta.check_texts')} t={t} />
        <MediaQuery at="sm">
          <div>
            <BWTicker tickerItems={t('cta.check_texts')} />
          </div>
        </MediaQuery>
        <Feedback />
      </div>
    </Layout>
  );
}

export default Home;
